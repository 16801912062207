
import LRU from "lru-cache"

//const LRU = require('lru-cache')
const cacheAge = 365 * 24 * 3600 * 1000 // -- 1 year :-)

export const microCache = new LRU({
  max: 100,
  maxAge: cacheAge
})

export const storedEvent = []
