class Event {
  constructor(){
    this.events = {};
  }
  $on(eventName, fn) {
    /*
    this.events[eventName] = this.events[eventName] || [];
    //this.events[eventName].push(fn);
    for(var i = 0; i < this.events[eventName].length; i++) {
      //if(i > 0) {
        if(this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
        }
      //}
    }
    */
    this.events[eventName] = []
    this.events[eventName].push(fn);
    //console.error('$on.'+eventName, typeof(this.events[eventName]), this.events[eventName].length)
  }
  $off(eventName, fn) {
    //console.log('$off type ('+eventName+'):', typeof(this.events[eventName]), fn)
    if(this.events[eventName]) {
      let flagFound = false
      //console.log('starts with:', this.events[eventName].length);
      for (var i = 0; i < this.events[eventName].length; i++) {
        if(!flagFound) {
          if (this.events[eventName][i] === fn) {
            this.events[eventName].splice(i, 1);
            flagFound = true
            //break;
          }
        } else {
          //console.log('remove: '+i, this.events[eventName].length);
          this.events[eventName].splice(i, 1);
        }
      }
    }
    //console.warn('$off2.'+eventName, this.events[eventName])
  }
  $emit(eventName, data) {
    //console.log('$emit ('+eventName+'):', typeof(this.events[eventName]), data)
    if (this.events[eventName]) {
      //console.warn('$emit.'+eventName, this.events[eventName].length)
      this.events[eventName].forEach(function(fn) {
        fn(data);
      });
    }
  }
}

export default new Event();
