<template>
  
  <div class="col-4 col-md-2 text-right">
    <div class="lang_place">
      <div id="main_menu_lang_block">
        <!--  using Vue3 syntax -->
        <!--
        <div v-tooltip="$t('menu.en')" data-lang="en" class="lang lang_en" :title="$t('menu.en')" @click="switchLanguage"></div>
        <div v-tooltip="$t('menu.ru')" data-lang="ru" class="lang lang_ru" :title="$t('menu.ru')" @click="switchLanguage"></div>
        -->
        <!--  using Vue2 syntax -->
        <div v-tooltip="$t('menu.en')" class="lang lang_en" :title="$t('menu.en')" @click="switchLocale('en')"></div>
        <div v-tooltip="$t('menu.ru')" class="lang lang_ru" :title="$t('menu.ru')" @click="switchLocale('ru')"></div>
      </div>
    </div>
  </div>
  
</template>

<script>
//##########################
//
//   using Vue2 syntax...
//
//##########################
import Trans from "@/i18n/translation"
import config from '@/config.js'

export default {
  name: 'LanguageSwitcher',
  computed: {
    supportedLocales () {
      if (!config.USE_MULTILINGUAL) {
        return []
      }
      return Trans.supportedLocales
    },
    currentLocale () {
      return Trans.currentLocale
    }
  },
  methods: {
    switchLocale(newLocale) {
      if (this.$i18n.locale !== newLocale) {
        const to = this.$router.resolve({ params: { locale: newLocale } })
        to.path = '/' + newLocale + this.$route.path.substr(3)
        return Trans.changeLocale(newLocale).then(() => {
          this.$router.push(to.path).catch(() => {
            this.$router.push("/")
          })
        })
      }
    }
  }
}

/*
//##########################
//
//   using Vue3 syntax...
//
//##########################
import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router"
import Tr from "@/i18n/translation"

export default {
  setup() {
    const { t, locale } = useI18n()
    const supportedLocales = Tr.supportedLocales
    const router = useRouter()
    const switchLanguage = async (event) => {
      const newLocale = event.target.attributes['data-lang'].value
      await Tr.switchLanguage(newLocale)
      try {
        await router.resolve({ params: { locale: newLocale } })
      } catch(e) {
        console.log(e)
        router.push("/")
      }
    }
    return { t, locale, supportedLocales, switchLanguage }
  }
}
*/
</script>
