import { createRouter, createWebHistory, RouterView } from 'vue-router'
import Trans from "@/i18n/translation"
//import config from '@/config.js'

function load (component) {
  // '@' is aliased to src/components
  return () => import(`@/pages/${component}.vue`)
}

const routes =
[
  {
    path: '/:locale?',
    component: RouterView,
    beforeEnter: Trans.routeMiddleware,
    children: [
      // -- HOME
      {
        path: '',
        name: 'Home',
        component: load('Home'),
      },
      //-- COUNTRY (using just for router, no real links)
      {
        path: 'country',
        name: 'Country',
        component: load('Country'),
        children: [
          // -- list of cities for given :country (Country)
          {
            path: ':country',
            name: 'Country',
            component: load('Country')
          },
        ],
      },
      //-- MAP
      {
        path: 'map',
        name: 'Map',
        component: load('Map'),
      },
      //-- GALLERY
      {
        path: 'gallery',
        name: 'Gallery',
        component: load('Gallery'),
        children: [
          // -- photo gallery for given :country
          {
            path: ':country',
            name: 'Gallery',
            component: load('Gallery'),
            children: [
              // -- photo gallery for given :country and :city
              {
                path: ':city',
                name: 'Gallery',
                component: load('Gallery'),
                children: [
                  // -- one photo for given :country, :city and :pics
                  {
                    path: ':pics',
                    name: 'Gallery',
                    component: load('Gallery')
                  },
                ],
              },
            ],
          },
        ]
      },
      //-- BLOG
      {
        path: 'blog',
        name: 'Blog',
        component: load('Blog'),
        children: [
          // -- fill text of article from blog :article (Blog)
          {
            path: ':article',
            name: 'Blog',
            component: load('Blog')
          },
        ],
      },
      //-- ERROR
      {
        path: 'error',
        name: 'Error',
        component: load('Error'),
      },
      {
        path: '/:locale?(.*)',
        name: 'AllPages',
        component: load('AllPages'),
        params: {
          locale: Trans.currentLocale
        },
        beforeEnter: () => {
          Trans.routeMiddleware
        },
        redirect: (to) => {
          console.log(111)
          let locale = to.path.substr(1,2);
          if(!Trans.isLocaleSupported(locale)) {
            locale = Trans.currentLocale
          }
          return "/" + locale + '/error';
        },
      },
    ]
  },
  {
    path: '/:locale(.*)',
    name: 'AllPages',
    component: load('AllPages'),
    beforeEnter: () => {
      Trans.routeMiddleware
    },
    redirect: (to) => {
      console.log(222)
      let locale = to.path.substr(1,2);
      if(!Trans.isLocaleSupported(locale)) {
        locale = Trans.currentLocale
      }
      return "/" + locale + '/error';
    },

  }
]

//console.error('routes:', routes)


const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  //base: process.env.BASE_URL,
  routes: routes
});


export default router
