
// import axios from 'axios'
// import { i18n } from '../i18n'
import { Url } from '@/plugins/Url.js'
//import EventBus from '@/components/common/EventBus.js'

const Thumbnail = {
  getPreviewName (params) {
    let fileName = ''
    let thumbName = ''

    // -- convert all params to string
    const k = ['w', 'h', 'w2', 'h2', 'mark_pos', 'contrast', 'brightness']
    k.forEach((element) => {
      if (typeof (params[element]) !== 'undefined') {
        params[element] = parseInt(params[element]).toString()
      }
    })
    if (typeof (params.id) !== 'undefined') {
      params.id = parseInt(params.id).toString()
      //params.id = '22458'
    }
    if (typeof (params.filename) !== 'undefined') {
      fileName = params.filename
      delete params.filename
      // params['filename'] = parseInt(params['id']).toString();
    }
    if (typeof (params.grayscale) !== 'undefined') {
      params.grayscale = '1'
    }

    // -- fill associative Object
    const assocObj = {}
    const sortedAssocObj = {}
    for (const key in params) {
      assocObj[key] = params[key]
    }

    // -- sort by keys (ksort)
    const keys = Object.keys(assocObj)
    keys.sort()
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i]
      sortedAssocObj[k] = assocObj[k]
    }

    // -- convert to JSON
    thumbName = JSON.stringify(sortedAssocObj)

    // -- convert to Base64
    const base64Str = btoa(thumbName)
    // const base64Str = Buffer.from(thumbName).toString('base64');

    // -- get file name and extension
    const fn = this.getFileNameAndExt(fileName)
    fileName = fn[0]
    const fileExt = fn[1]

    fileName += '_' + base64Str + '_preview.' + fileExt

    return fileName
  },
  getFileNameAndExt (path) {
    const filenameextension = path.replace(/^.*[\\/]/, '')
    const filename = filenameextension.substring(0, filenameextension.lastIndexOf('.'))
    const ext = filenameextension.split('.').pop()

    return [filename, ext]
  },
  makeGalleryItemsArray (data) {
    const assocArr = []
    for (let i = 0; i < data.length; i++) {
      const k = data[i]

      const p = []
      p.filename = k.name
      //p.w = 450
      p.w = 170
      p.id = k.id

      const path = process.env.VUE_APP_API_BASEURL + 'data/storage/' + k.path + '/'
      const src = path + k.name
      let thumbnail = path + Thumbnail.getPreviewName(p)

      const gItem = {
        src: src,
        thumbnail: thumbnail,
        w: 2048,
        h: 1364,
        gid: 1,
        pid: k.pid + '-' + k.smart_address,
        title: k.ptitle,
        likes: k.likes
      }

      this.checkImage(gItem);

      assocArr.push(gItem)
    }

    return assocArr
  },
  createImageThumbnail(gItem)
  {
    // -- get axios data for "dsCountriesNextPrev"
    this.EventBus.$off('create-thumbnail-get-axios-data')
    this.EventBus.$on('create-thumbnail-get-axios-data', (data) => {
      //-- get PID from Axios
      var pid = parseInt(data['pid']);
      if(typeof(pid) !== 'undefined') {
        //-- get all A-tags
        var a = $("a[pid]");
        a.each(function() {
          //-- get PID of A-tag
          var p = $(this).attr('pid');
          if(typeof(p) !== 'undefined') {
            //-- make ID from PID
            id = parseInt(p);
            //-- found!
            if(id == pid) {
              //-- find image
              var img = $(this).find('img');
              if(img.length > 0) {
                //-- replace SRC of image by SRC from Axios
                img.attr('src', data['src']);
                return true;
              }
            }
          }
        });
      }
    });
    
    var id = parseInt(gItem.pid)
    const axiosData = {
      id: id
    }
    Url.getAxiosData(
      axiosData,
      'dsCreateThumbnail_' + id,
      'web/create-thumbnail',
      'create-thumbnail-get-axios-data'
    )
  },
  checkImage(gItem)
  {
    var url = gItem.thumbnail
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function() {
      var status = request.status;
      //console.log("status: "+status+' ('+url+')');
      if(status != 200) {
        //console.log("image doesn't exist: ", gItem);
        Thumbnail.createImageThumbnail(gItem)
      }
    }
  }
}

export {
  Thumbnail
}
