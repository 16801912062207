<template>
  <div class="col-auto main_title_icon"><img :src="dsTitleIcon"/></div>
</template>

<script>
import { Url } from '@/plugins/Url.js'

export default {
  name: 'TitleIcon',
  data () {
    return {
      dsTitleIcon: ''
    }
  },
  created () {
    this.EventBus.$off('title-get-title-extra')
    this.EventBus.$on('title-get-title-extra', (data) => {
      this.getTitleIcon(data)
    })
  },
  methods: {
    getTitleIcon (data) {
      const code = data.code
      const urlParams = Url.getUrlParams()
      // -- URL: /country/at (from Home Page)
      let extraStr = ''
      if (urlParams.pathPage === 'country') {
        extraStr = Url.setFlag(code)
      }
      if (urlParams.pathPage === 'gallery' && typeof (urlParams.pathCode) !== 'undefined' && typeof (urlParams.pathCode2) === 'undefined') {
        extraStr = Url.setFlag(code)
      }
      this.dsTitleIcon = extraStr
    }
  }
}
</script>
