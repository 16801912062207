<template>
  <div class="row w-100 no-gutters main_title">
    <TitleIcon/>
    <div class="col-auto main_title_text"><h1>{{ dsTitles.title }}</h1></div>
  </div>
</template>

<script>
import config from '@/config.js'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import TitleIcon from '@/components/common/TitleIcon.vue'
import { Url } from '@/plugins/Url.js'

const DEFAULT_TITLE = 'Mike Mozolin'
const DEFAULT_DELIMITER = ' | '

export default {
  name: 'Title',
  data() {
    return {
      url: '',
      dsTitles: [],
    }
  },
  components: {
    TitleIcon,
  },
  computed: {
    getErrorPage() {
      if(typeof(this.dsTitles.name) !== 'undefined' && this.dsTitles.name === 'Error') {
        return true
      }
      return false
    },
  },
  created () {
    
    //console.log(1, 'title-get-title', this.EventBus);
    this.EventBus.$off('title-get-title')
    this.EventBus.$on('title-get-title', (data) => {
      //console.warn('title-get-title::data', data)
      this.getTitle(data)
    })
    this.EventBus.$off('title-get-axios-data')
    this.EventBus.$on('title-get-axios-data', (data) => {
      
      //-- handle wrong data for the page => redirect to Error-page
      if(typeof(data.name) !== 'undefined' && data.name === 'Error') {
        
        let redirectOnError = '/error'
        if(config.USE_MULTILINGUAL) {
          redirectOnError = '/' + this.$i18n.locale + '/error'
        }

        const errorParams = {
          name: 'Error',
          path: redirectOnError,
          component: this.load('Error'),
          params: {
            locale: this.$i18n.locale
          },
        }

        this.$router
          //.push(redirectOnError)
          .push(errorParams)
          .catch((e) => {
            if(!isNavigationFailure(e, NavigationFailureType.redirected)) {
              Promise.reject(e)
            }
          })

      }

      //console.warn('title-get-axios-data::data', data, this.EventBus)

      this.dsTitles = data
      if (typeof (data.title) !== 'undefined') {
        document.title = data.title + DEFAULT_DELIMITER + DEFAULT_TITLE
      }
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        this.fromRoute = from
        
        document.title = DEFAULT_TITLE
        
        if (typeof (to.path) !== 'undefined') {
          this.url = to.path
          this.from = from
          this.getTitle()
        }
        if (typeof (to.meta.title) !== 'undefined') {
          document.title = to.meta.title + DEFAULT_DELIMITER + DEFAULT_TITLE
        }
      }
    }
  },
  methods: {
    getTitle () {
      const lang = this.$i18n.locale
      const uniqueUrl = this.url.replace('/', '_')

      const axiosData = {
        route: this.url,
        lang: lang
      }
      
      // -- (data, cacheId, apiUrl, emitEvent)
      Url.getAxiosData(
        axiosData,
        'dsTitles_' + uniqueUrl + '_' + lang,
        'web/titles',
        'title-get-axios-data'
      )
    },
    load (component) {
      // '@' is aliased to src/components
      return () => import(`@/pages/${component}.vue`)
    },
  }
}
</script>
