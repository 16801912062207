<template>
  <div id="mozolin_main">
    
    <section id="mozolin_top">
      <div class="row">
        <MainMenu/>
        <LanguageSwitcher/>
      </div>
    </section>
    
    <Routes/>
    
    <div style="display:none;">{{ this.$route.name }}</div>
    
    <Title/>
    
    <div class="main_page_block">
      <RouterView/>
    </div>
    
    <section id="mozolin_bottom">
      <div class="row">
        <PageFooter/>
      </div>
    </section>

  </div>
</template>

<script>
import LanguageSwitcher from '@/components/common/LanguageSwitcher.vue'
import MainMenu from '@/components/common/MainMenu.vue'
import { Url } from '@/plugins/Url.js'
import PageFooter from '@/components/common/PageFooter.vue'
import config from '@/config.js'
import Routes from '@/components/common/Routes.vue'
import Title from '@/components/common/Title.vue'
//import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    LanguageSwitcher,
    MainMenu,
    PageFooter,
    Routes,
    Title,
    //RouterView,
  },
  data () {
    return {
      dsAppLikes: 0,
      appKeyPressedCount: 0,
      updatedFlag: false,
      params: [],
      DEBUG: false,
    }
  },
  
  /*
  beforeCreate() {
    if(this.DEBUG) {
      console.log('...beforeCreate');
    }
  },
  created() {
    if(this.DEBUG) {
      console.log('...created');
    }
  },
  beforeMount() {
    if(this.DEBUG) {
      console.log('...beforeMount');
    }
  },
  mounted2() {
    if(this.DEBUG) {
      console.log('...mounted');
    }
  },
  beforeUpdate() {
    if(this.DEBUG) {
      console.log('...beforeUpdate');
    }
  },
  updated2() {
    if(this.DEBUG) {
      console.log('...updated');
    }
  },
  activated() {
    if(this.DEBUG) {
      console.log('...activated');
    }
  },
  deactivated() {
    if(this.DEBUG) {
      console.log('...deactivated');
    }
  },
  beforeUnmount() {
    if(this.DEBUG) {
      console.log('...beforeUnmount');
    }
  },
  unmounted() {
    if(this.DEBUG) {
      console.log('...unmounted');
    }
  },
  errorCaptured() {
    if(this.DEBUG) {
      console.log('...errorCaptured');
    }
  },
  renderTracked() {
    if(this.DEBUG) {
      console.log('...renderTracked');
    }
  },
  renderTriggered() {
    if(this.DEBUG) {
      console.log('...renderTriggered');
    }
  },
  */


  mounted () {
    if(this.DEBUG) {
      console.log('...mounted')
    }
    
    /*
    window.addEventListener('click', this.clickHandler)
    //const params = Url.makeUrlParams()
    //console.warn('App.vue', this.$route.name, params, RouterView);
    this.appKeyPressedCount += 1;


    setTimeout(function() {
      var link = document.getElementsByTagName("body")[0];
      link.click();
    }, 500)
    */
  },
  destroyed () {
    if(this.DEBUG) {
      console.log('...destroyed');
    }
    window.removeEventListener('click', this.clickHandler)
    //this.appKeyPressedCount += 1;
  },
  updated () {
    if(this.DEBUG) {
      console.log('...updated');
    }
    
    // -- call remote method to add current route to router
    this.EventBus.$emit('routes-get-route')

    const params = Url.makeUrlParams()
    if (config.USE_MULTILINGUAL && params.lang === '') {
      return false
    }

    //console.warn('App.vue::route.name =', this.$route.name);
    //console.warn('App.vue::route.params =', this.$route.params);
    //console.warn('App.vue::Url.params =', params);
    
    this.EventBus.$emit('title-get-title', params)
    this.EventBus.$emit('title-get-title-extra', params)

    // -- URL: /
    if (this.$route.name === 'Home') {
      this.EventBus.$emit('home-get-countries', params)
      //this.EventBus.$emit('get-phpversion', params)
      this.EventBus.$emit('common-get-config', params)
    }
    // -- URL: /gallery
    if (this.$route.name === 'Gallery') {
      this.EventBus.$emit('gallery-get-countries', params)
      this.EventBus.$emit('gallery-get-gallery-countries', params)
    }
    // -- URL: /blog
    if ((this.$route.name === 'Blog' || params.page === 'blog') && typeof(params.code) === 'undefined') {
      this.EventBus.$emit('blog-get-blogs', params)
      this.EventBus.$emit('blog-get-blog-alllikes', params)
    }
    
    //-- URL: /gallery/uk-en
    if (params.page === 'gallery' && typeof (params.code) !== 'undefined' && typeof (params.code2) === 'undefined') {
      this.EventBus.$emit('gallery-get-gallery-countries', params)
      this.EventBus.$emit('country-get-countries-nextprev', params)
    }
    //-- URL: /gallery/uk-en/london => city
    //-- URL: /gallery/ru/leningrad_region => region
    if (params.page === 'gallery' && typeof (params.code) !== 'undefined' && typeof (params.code2) !== 'undefined') {
      //console.log('REGIONS OF RUSSIA');
      if(params.code === 'ru') {
        //-- REGIONS OF RUSSIA!
        this.EventBus.$emit('gallery-get-gallery-regions', params)
        this.EventBus.$emit('country-get-regions-nextprev', params)
      } else {
        //-- LIST OF CITIES (EXCEPT RUSSIA)
        //console.log('foreign:', params);
        this.EventBus.$emit('city-get-pics', params)
        this.EventBus.$emit('city-get-info', params)
        this.EventBus.$emit('city-get-cities-nextprev', params)
      }
    }
    // -- URL: /gallery/uk-en/london/8354
    // -- URL: /gallery/ru/leningrad_region/saint_petersburg
    if (params.page === 'gallery' && typeof (params.code) !== 'undefined' && typeof (params.code2) !== 'undefined' && typeof (params.id) !== 'undefined') {
      if(params.code === 'ru') {
        //-- cities list of Russian region
        params.code2 = params.id;
        params.id = undefined;
        this.EventBus.$emit('city-get-pics', params)
        this.EventBus.$emit('city-get-cities-nextprev', params)
      } else {
        //-- one photo (not for Russia)
        this.EventBus.$emit('city-get-onepic', params)
      }
    }
    // -- URL: /country/at (from Home Page)
    if (params.page === 'country' && typeof(params.code) !== 'undefined' && params.code.length === 2 && typeof(params.code2) === 'undefined' && typeof(params.id) === 'undefined') {
      // -- Cities Info block
      this.EventBus.$emit('country-get-citiesinfo', params)
      //-- Country Info
      this.EventBus.$emit('country-get-country-info', params)
      this.EventBus.$emit('country-get-cities', params)
      this.EventBus.$emit('country-get-countries-nextprev', params)
      this.EventBus.$emit('map-get-google', params)
      // -- Country Articles
      this.EventBus.$emit('country-get-country-articles', params)
    } else {
      $('#map_google').hide();
    }
    
    // -- URL: /map
    if (this.$route.name === 'Map' || params.page === 'map') {
      this.EventBus.$emit('map-get-table', params)
    }
    // -- URL: /blog
    if ((this.$route.name === 'Blog' || params.page === 'blog') && typeof(params.code) !== 'undefined') {
      if(params.code === 'world') {
        // -- Blog World
        this.EventBus.$emit('blog-get-blog-world', params)
      } else {
        // -- Blog Article
        this.EventBus.$emit('blog-get-blog-article', params)
      }
      this.EventBus.$emit('blog-get-blogs-nextprev', params)
      this.EventBus.$emit('blog-get-blog-likes', params)
    }
    
    // -- URL: /google/at (Google Maps)
    if (params.page === 'google' && typeof(params.code) !== 'undefined' && params.code.length === 2 && typeof(params.code2) === 'undefined' && typeof(params.id) === 'undefined') {
      this.EventBus.$emit('map-get-google', params)
    }
    
    //console.log('EventBus:', this.EventBus);
  },
  methods: {
    clickHandler(e) {

      //console.log('loc: ', window.location.pathname)
      
      /*
      if(e.target.tagName === 'BODY') {
        //-- change contents to fire update event
        this.appKeyPressedCount += 1;
      }
      */

      //console.error('clicked on ', e, e.target.className, e.target.tagName)
      if(e.target.className === 'row no-gutters text' && e.target.tagName === 'DIV') {
        
        /*
        //-- click on one of menu buttons
        let clickFlag = true;
        const parentEl = e.target.parentElement
        if(parentEl.tagName === 'A') {
          const pathName = parentEl.pathname
          console.log(pathName + ' === ' + window.location.pathname)
          //-- THE SAME LINK! => do not click on BODY
          if(pathName === window.location.pathname) {
            console.log('THE SAME LINK!')
            clickFlag = false;
          }
        }
        
        if(clickFlag) {
        */
          /*
          //-- do click on BODY
          setTimeout(function() {
            var link = document.getElementsByTagName("body")[0];
            link.click();
          }, 500)
          */
        /*
        }
        */
      }
      
      //-- trying to make link like "router-link"
      if(e.target.tagName === 'A') {
        e.preventDefault();
        
        const href = e.target.href;
        if(typeof(href) == 'undefined') {
          return false;
        }
        const path = window.location.pathname;
        if(typeof(path) == 'undefined' || path.length == 0) {
          return false;
        }
        
        this.$router.push(path).catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
      return false;
    },
  },

}
</script>
