<template>
  <div></div>
</template>

<script>
//import config from '@/config.js'
import { Url } from '@/plugins/Url.js'

export default {
  name: 'Routes',
  mounted () {
    this.EventBus.$off('routes-get-route')
    this.EventBus.$on('routes-get-route', (data) => {
      // Url.isRouteExists('mounted');
      this.getRoute(data)
    })
  },
  methods: {
    load (component) {
      // -- '@' is aliased to src/components
      return () => import(`@/pages/${component}.vue`)
    },
    highlightMainMenu () {
      const pathPage = Url.getUrlParams().pathPage

      // -- which pathPage uses the menu
      let usingMenu = ''
      if (pathPage === '' || pathPage === 'country') {
        usingMenu = 'home'
      } else if (pathPage === 'map') {
        usingMenu = 'map'
      } else if (pathPage === 'gallery') {
        usingMenu = 'gallery'
      } else if (pathPage === 'blog') {
        usingMenu = 'blog'
      }

      const objMenu = $('html').find('#main-menu-' + usingMenu)
      if (objMenu.length === 1) {
        const objRL = $('html').find('.router-link')
        // -- remove active style for all <router-link>
        objRL.each(function () {
          $(this).removeClass('router-link-active	')
          $(this).removeClass('router-link-exact-active')
        })
        // -- add active style for "usingMenu"
        objMenu.addClass('router-link-active')
        objMenu.addClass('router-link-exact-active')
      }
    },
    getRoute (/*data*/) {
      //console.log('getRoute', data);
      return false;
      
      /*
      this.highlightMainMenu(data)

      const urlParams = Url.getUrlParams()

      let newPath = null
      // let newName = null;
      let newComponent = null

      let parentPath = null
      let parentName = null
      let parentComponent = null

      let isNewChild = false

      // -- URL: /gallery/uk-en
      // -- URL: /gallery/uk-en/london
      if (urlParams.pathPage === 'gallery' && typeof (urlParams.pathCode) !== 'undefined') {
        newPath = '/gallery/' + urlParams.pathCode
        parentPath = '/gallery'
        if (config.USE_MULTILINGUAL) {
          newPath = '/:locale' + newPath
          parentPath = '/:locale' + newPath
        }
        // newName = 'gallery_' + urlParams.pathCode;
        parentName = 'gallery'
        if (typeof (urlParams.pathCode2) !== 'undefined') {
          // newName += '_' + urlParams.pathCode2;
          newPath += '/' + urlParams.pathCode2

          parentName += '_' + urlParams.pathCode
          parentPath += '/' + urlParams.pathCode
        }
        newComponent = this.load('Gallery')
        parentComponent = this.load('Gallery')

        isNewChild = true
      }

      // -- URL: /country/uk-en (HOME PAGE)
      if (urlParams.pathPage === 'country' && typeof (urlParams.pathCode) !== 'undefined') {
        newPath = '/country/' + urlParams.pathCode
        if (config.USE_MULTILINGUAL) {
          newPath = '/:locale' + newPath
        }
        // newName = 'Country';
        newComponent = this.load('Country')

        isNewChild = true
      }

      if (isNewChild) {
        // -- build Parent Route
        let parentRoute = null
        if (parentPath !== null && parentName !== null) {
          parentRoute = {
            path: `${parentPath}`,
            name: `${parentName}`,
            component: parentComponent
          }
        }

        // -- build New Route
        const newRoute = {
          path: `${newPath}`,
          component: newComponent
        }
        if (parentRoute !== null) {
          newRoute.parent = parentRoute
        }
        
      }
      */
    }
  }
}
</script>
