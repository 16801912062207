import { createI18n } from "vue-i18n"
import pluralRules from "./rules/pluralization"
import numberFormats from "./rules/numbers.js"
import datetimeFormats from "./rules/datetime.js"
//import en from "./locales/en.json"


function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


export default createI18n({
  locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  //messages: { en },
  messages: loadLocaleMessages(),
  runtimeOnly: false,
  pluralRules,
  numberFormats,
  datetimeFormats
})
