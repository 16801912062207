
export const Funcs = {
  // -- date: yyyy-mm-dd
  // -- locale: ru-RU
  dateFormat (date, locale) {
    if (typeof (locale) === 'undefined') {
      locale = 'ru-RU'
    }
    return (new Date(date)).toLocaleDateString(locale)
  },
  number_format (number, decimals = 0, dec_point = '.', thousands_sep = ',') {
    const sign = number < 0 ? '-' : ''

    const s_number = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + ''
    const len = s_number.length
    const tchunk = len > 3 ? len % 3 : 0

    const ch_first = (tchunk ? s_number.substr(0, tchunk) + thousands_sep : '')
    const ch_rest = s_number.substr(tchunk)
      .replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep)
    const ch_last = decimals
      ? dec_point + (Math.abs(number) - s_number)
        .toFixed(decimals)
        .slice(2)
      : ''

    return sign + ch_first + ch_rest + ch_last
  },
  scrollToAnchor (objName, tag = 'a') {
    const tagObj = $(tag + "[name='" + objName + "']")
    //console.log(tagObj, tagObj.length, tagObj.offset(), tagObj.position())

    try {
      if (typeof (tagObj.offset()) !== 'undefined') {
        $('html, body').stop().animate({
          scrollTop: tagObj.offset().top - 10
        }, 500)
      }
    } catch (e) {
      this.error.push(e);
    }
  },
  scrollToTop () {
    $('html, body').stop().animate({
      scrollTop: 0
    }, 500)
  },
  randomString() {
    return (Math.random() + 1).toString(36).substring(2);
  },
  randomNumber() {
    const list = [1,2,3,4,5,6,7,8,9];
    while(
      list.findIndex((v, i) => {
        return v == i + 1 || (i && Math.abs(list[i - 1] - v) == 1);
      }) != -1
    )
    {
      list.sort(() => {
        return Math.random() - 0.5;
      });
    }
    return list.join('');
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
