export default {
  en: {
    currencyFormat: {
      style: "currency",
      currency: "USD"
    }
  },
  ru: {
    currencyFormat: {
      style: "currency",
      currency: "RUB"
    }
  }
}