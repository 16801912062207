
import router from '@/router'
import config from '@/config.js'
import axios from 'axios'
import { microCache } from '@/components/common/Cache.js'
import { Thumbnail } from '@/plugins/Thumbnail'
import EventBus from '@/components/common/EventBus.js';
import { getCurrentInstance } from 'vue'
//import {EventBus} from '@/main.js';


export const Url = {
  setImage (id, path, name, width) {
    const p = []
    p.filename = name
    p.w = width
    p.id = id
    return process.env.VUE_APP_API_BASEURL + 'data/storage/' + path + '/' + Thumbnail.getPreviewName(p)
  },
  setEmptyImage () {
    //return process.env.VUE_APP_API_BASEURL + 'images/pix.png'
    return process.env.VUE_APP_API_BASEURL + 'images/pix_3x2.png'
  },
  setFlagHuge (code) {
    return process.env.VUE_APP_API_BASEURL + 'images/countries/flags_huge/' + code + '_450x300.png'
  },
  setFlag (code) {
    return process.env.VUE_APP_API_BASEURL + 'images/countries/flags/flag_' + code.toUpperCase() + '.png'
  },
  setRegionFlag(code) {
    return process.env.VUE_APP_API_BASEURL + 'images/regions/' + code + '.png'
  },
  setHrefOne (lnk, code, code2, smart_address) {
    const params = this.getUrlParams()
    let href = '/' + lnk + '/' + code
    if (typeof (code2) !== 'undefined') {
      href += '/' + code2
    }
    if (typeof (smart_address) !== 'undefined') {
      href += '/' + smart_address
    }
    if (config.USE_MULTILINGUAL && typeof (params.pathLang) !== 'undefined' && params.pathLang.length == 2) {
      href = '/' + params.pathLang + href
    }
    //console.warn(href);
    return href
  },
  setHref (links, code, code2, smart_address) {
    const params = this.getUrlParams()
    if (!Array.isArray(links)) {
      return this.setHrefOne(links, code, code2, smart_address)
    } else {
      for (let i = 0; i < links.length; i++) {
        const lnk = links[i]
        if (params.pathPage == lnk) {
          return this.setHrefOne(lnk, code, code2, smart_address)
        }
      }
    }
    return null
  },
  createUrl(arr) {
    let url = ''
    if(typeof(arr) !== 'undefined' && Array.isArray(arr) && arr.length > 0) {
      for(let i=0; i<arr.length; i++) {
        url += '/' + arr[i]
      }
    } else {
      const params = this.makeUrlParams()
      $.each(params, function(k, v) {
        if(typeof(v) !== 'undefined') {
          url += '/' + v
        }
      });
    }
    return url
  },
  createUrlAsArray() {
    let url = []
    const params = this.makeUrlParams()
    $.each(params, function(k, v) {
      if(typeof(v) !== 'undefined') {
        url.push(v)
      }
    });
    return url
  },
  makeUrlParams() {
    const urlParams = this.getUrlParams()
    if (config.USE_MULTILINGUAL && urlParams.pathLang === '') {
      return false
    }

    if(typeof(urlParams.pathPage) === 'undefined') {
      urlParams.pathPage = 'home';
    }
    
    const params = {
      lang: urlParams.pathLang,
      page: urlParams.pathPage,
      code: urlParams.pathCode,
      code2: urlParams.pathCode2,
      id: urlParams.pathId,
      likes: this.dsAppLikes
    }
    
    return params
  },
  setUrlParams (urlParams) {
    let result = ''
    if (typeof (urlParams.pathLang) !== 'undefined') {
      result += '/' + urlParams.pathLang
    }
    if (typeof (urlParams.pathPage) !== 'undefined') {
      result += '/' + urlParams.pathPage
    }
    if (typeof (urlParams.pathCode) !== 'undefined') {
      result += '/' + urlParams.pathCode
    }
    if (typeof (urlParams.pathCode2) !== 'undefined') {
      result += '/' + urlParams.pathCode2
    }
    if (typeof (urlParams.pathId) !== 'undefined') {
      result += '/' + urlParams.pathId
    }
    return result
  },
  getUrlParams () {
    // -- get URL: /ru/gallery/uk-en/london/15219
    // -- pathLang:ru, pathPage:gallery, pathCode:uk-en, pathCode2:london, pathId:15219
    const path = window.location.pathname.substring(1)
    let [pathLang, pathPage, pathCode, pathCode2, pathId] = []
    if (config.USE_MULTILINGUAL) {
      [pathLang, pathPage, pathCode, pathCode2, pathId] = path.split('/')
    } else {
      pathLang = 'ru';
      [pathPage, pathCode, pathCode2, pathId] = path.split('/')
    }
    return { pathLang, pathPage, pathCode, pathCode2, pathId }
  },
  parseGalleryCountry () {
    const urlParams = this.getUrlParams()

    // -- /ru/gallery_uk-en/london
    const regExp = /([-a-zA-Z0-9@:%._+~#=]{1,256})_([-a-zA-Z0-9@:%._+~#=]{1,256})/gi
    const regex = new RegExp(regExp)
    const res = urlParams.pathPage.match(regex)
    if (res && typeof (urlParams.pathCode) !== 'undefined') {
      let page = ''
      let country = ''
      if (typeof (res[0]) !== 'undefined') {
        const arr = res[0].split('_')
        if (arr.length === 2) {
          page = arr[0]
          country = arr[1]
          return { type: page, country: country, code: urlParams.pathCode }
        }
      }
    }
    return false
  },
  isDetailPage () {
    const urlParams = this.getUrlParams()

    // -- /ru/gallery_uk-en/london
    const isGalleryCountry = this.parseGalleryCountry()
    if (isGalleryCountry !== false) {
      return isGalleryCountry
    }

    // -- /ru/city/london
    // -- /ru/country/uk-en
    // -- /ru/gallery/uk-en
    if (typeof (urlParams.pathCode) !== 'undefined' && typeof (urlParams.pathCode2) === 'undefined') {
      return { type: urlParams.pathPage, code: urlParams.pathCode }
    }

    // -- /ru/gallery/uk-en/london
    if (typeof (urlParams.pathCode) !== 'undefined' && typeof (urlParams.pathCode2) !== 'undefined') {
      return { type: urlParams.pathPage, country: urlParams.pathCode, code: urlParams.pathCode2 }
    }
    return false
  },
  isRouteExists (mode) {
    let routeExists = false
    const currentRoute = router.currentRoute
    const allRoutes = router.getRoutes()

    if (typeof (mode) !== 'undefined') {
      console.warn(mode)
    }

    for (const route of allRoutes) {
      if (typeof (route.name) !== 'undefined' && route.name === currentRoute.name) {
        routeExists = true
        break
      }
    }
    return routeExists
  },
  loadElement(tag, id, text) {
    var element =  document.getElementById(id);
    if(typeof(element) != 'undefined' && element != null) {
      //-- exists!
      return false;
    }
    var el = document.createElement(tag);
    el.id = id
    //el.style.width = "100px";
    //el.style.height = "100px";
    el.innerHTML = text;
    document.body.appendChild(el);
    //-- create element from HTML code and put it before the end of page
    //document.body.insertAdjacentHTML('beforeend', html);
  },
  loadStyle (href) {
    const list = document.head.getElementsByTagName('link')
    let i = list.length; let flag = false
    while (i--) {
      const src = list[i].href
      if (src.includes(href)) {
        flag = true
        break
      }
    }
    if (!flag) {
      const tag = document.createElement('link')
      tag.href = href
      tag.rel = "stylesheet"
      tag.type = "text/css"
      tag.media = "screen"
      document.getElementsByTagName('head')[0].appendChild(tag)
    }
  },
  loadScript(url, conditionFunc, callbackFunc) {
    const list = document.head.getElementsByTagName('script')
    let i = list.length; let flag = false
    while (i--) {
      const src = list[i].src
      if (src.includes(url)) {
        flag = true
        break
      }
    }
    if (!flag) {
      const tag = document.createElement('script')
      
      if(typeof(conditionFunc) === 'function' && typeof(callbackFunc) === 'function') {
        //-- waiting for loading...
        tag.onload = () => {
          if(conditionFunc()) {
            //-- if this condition is true, call this function
            callbackFunc()
          }
        }
      }
      
      tag.src = url
      document.getElementsByTagName('head')[0].appendChild(tag)
    }
  },
  currInstance() {
    let result = ''
    if(typeof(getCurrentInstance()) !== 'undefined' && getCurrentInstance() !== null) {
      if(typeof(getCurrentInstance().type) !== 'undefined' && getCurrentInstance().type !== null) {
        if(typeof(getCurrentInstance().type.name) !== 'undefined' && getCurrentInstance().type.name !== null) {
          result = getCurrentInstance().type.name.toString();
        }
      }
    }
    return result
  },
  axiosDataName(extraName) {
    let n = this.currInstance();
    if(n.length === 0) {
      return null;
    }
    n = this.currInstance().toLowerCase() + '-get-axios-data'
    if(typeof(extraName) !== 'undefined') {
      n += '-' + extraName
    }
    return n
  },
  getAxiosData (data, cacheId, apiUrl, emitEvent) {
    const params = new URLSearchParams()
    for (const k in data) {
      params.append(k, data[k])
    }

    let cachedData = null
    if (config.USE_MICROCACHE) {
      cachedData = microCache.get(cacheId)
    }

    //console.log('Url1', config.USE_MICROCACHE, cachedData)


    //cachedData = false;
    
    if (config.USE_MICROCACHE && cachedData) {
      //console.error('CACHE! ('+emitEvent+')', config.USE_MICROCACHE, '&&', cachedData)
      EventBus.$emit(emitEvent, cachedData)
      // -- hide loader
    } else {
      //console.log('DIRECT!', process.env.VUE_APP_API_BASEURL + apiUrl, params)
      axios
        .post(process.env.VUE_APP_API_BASEURL + apiUrl, params)
        .then(response => {
          const data = response.data
          //console.log('Url3', data, response.status)
          if (response.status === 200) {
            if (config.USE_MICROCACHE) {
              microCache.set(cacheId, data)
            }
            //console.error('DIRECT...', emitEvent, data);
            EventBus.$emit(emitEvent, data)
          } else {
            //this.error.push(e);
            console.error(response.status, data)
          }
          // -- hide loader
        })
        .catch(e => {
          //this.error.push(e)
          console.error(e, this.error)
        })
    }
  },
  getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    var pc = new myPeerConnection({
      iceServers: []
    }),
    noop = function() {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

    function iterateIP(ip) {
      if (!localIPs[ip]) {
        onNewIP(ip);
      }
      localIPs[ip] = true;
    }

    //create a bogus data channel
    pc.createDataChannel("");

    // create offer and set local description
    pc.createOffer().then(function(sdp) {
      sdp.sdp.split('\n').forEach(function(line) {
        if (line.indexOf('candidate') < 0) {
          return;
        }
        line.match(ipRegex).forEach(iterateIP);
      });
      pc.setLocalDescription(sdp, noop, noop);
    }).catch(function(reason) {
      // An error occurred, so handle the failure to connect
      console.error('reason:', reason)
    });

    //listen for candidate events
    pc.onicecandidate = function(ice) {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) {
        return;
      }
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  }
}

export default Url
