<template>
  <nav v-if="useMultilingual" class="col-8 col-md-10 navbar navbar-expand-md sticky-top">
    <div class="navbar-header">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="ul navbar-nav row w-100 no-gutters align-bottom align-text-bottom">
        <div class="menu col-auto li nav-item menu-item" @click="closeNav">
          <RouterLink class="router-link" id="main-menu-home" :to="Trans.i18nRoute({ name: 'Home' })">
            <div class="row no-gutters text">
            {{ $t('menu.home') }}
            </div>
          </RouterLink>
        </div>
        <div class="menu col-auto li nav-item menu-item" @click="closeNav">
          <RouterLink class="router-link" id="main-menu-map" :to="Trans.i18nRoute({ name: 'Map' })">
            <div class="row no-gutters text">
            {{ $t('menu.map') }}
            </div>
          </RouterLink>
        </div>
        <div class="menu col-auto li nav-item menu-item" @click="closeNav">
          <RouterLink class="router-link" id="main-menu-gallery" :to="Trans.i18nRoute({ name: 'Gallery' })">
            <div class="row no-gutters text">
            {{ $t('menu.gallery') }}
            </div>
          </RouterLink>
        </div>
        <div class="menu col-auto li nav-item menu-item" @click="closeNav">
          <RouterLink class="router-link" id="main-menu-blog" :to="Trans.i18nRoute({ name: 'Blog' })">
            <div class="row no-gutters text">
            {{ $t('menu.blog') }}
            </div>
          </RouterLink>
        </div>
        <div class="col-3 d-none d-md-block"></div>
      </div>
    </div>
  </nav>
  
</template>

<script>
import config from '@/config.js'
import Trans from "@/i18n/translation"

export default {
  name: 'MainMenu',
  computed: {
    useMultilingual () {
      return config.USE_MULTILINGUAL
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyDownHandler)
  },
  destroyed () {
    window.removeEventListener('keydown', this.keyDownHandler)
  },
  methods: {
    keyDownHandler (event) {
      if (event.code === 'Escape') {
        this.closeNav()
      }
    },
    closeNav () {
      $('#navbarCollapse').removeClass('show')
    }
  },
  setup() {
    return { Trans }
  }
}
</script>
