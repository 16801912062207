
//-- [import components]
import { createApp, h } from "vue";

import App from '@/App.vue'
import config from '@/config.js'
import router from '@/router'
import i18n from "@/i18n"
import Trans from "@/i18n/translation"
// -- https://github.com/f/vue-wait
import { createVueWait } from 'vue-wait'
import EventBus from '@/components/common/EventBus.js';
// -- https://floating-vue.starpad.dev/
import FloatingVue from 'floating-vue'
import FloatingVueConfig from '@/plugins/FloatingVueConfig.js'
//-- https://vue-final-modal.org/
import { createVfm } from 'vue-final-modal'
import $ from "jquery";



//-- [import styles]
import 'floating-vue/dist/style.css'
import 'vue-final-modal/style.css'
import '@/styles/index.scss'


//-- [define]
const VueWait = createVueWait({
  useVuex: false, // Uses Vuex to manage wait state
  vuexModuleName: 'wait', // Vuex module name
  registerComponent: true, // Registers `v-wait` component
  componentName: 'v-wait', // <v-wait> component name, you can set `my-loader` etc.
  registerDirective: true, // Registers `v-wait` directive
  directiveName: 'wait' // <span v-wait /> directive name, you can set `my-loader` etc.
})


//-- [create app]
const app = createApp({
  i18n,
  router,
  render: () => h(App)
})

//-- [config]
if(config.USE_MULTILINGUAL) {
  app.config.globalProperties.$i18nRoute = Trans.i18nRoute.bind(Trans)
}
app.config.productionTip = true
app.config.globalProperties.EventBus = EventBus;

//-- [use]
app.use($)
app.use(i18n)
app.use(router)
app.use(VueWait)
app.use(FloatingVue, FloatingVueConfig)
const vfm = createVfm()
app.use(vfm)


//-- [mount & export]
app.mount('#app')
export default { app, EventBus }
