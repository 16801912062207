<template>
  <div class="container" id="mozolin_footer">

    <div class="row w-100 no-gutters justify-content-center d-flex" id="main_page_go_to_the_top_row" @click="goToTop">
      <div class="col-auto click" align="center">
        <div class="row w-100 no-gutters justify-content-center d-flex">
          <div class="col-auto" id="main_page_go_to_the_top"></div>
          <div class="col-auto text text-center my-auto"><div>{{ $t('nav.goto_top') }}</div></div>
        </div>
      </div>
    </div>

    
    <div class="row w-100 no-gutters justify-content-center d-flex">
      <div class="row w-100 no-gutters align-items-center h-100">
        <div class="col mx-auto text">
          <div class="row w-100 no-gutters" id="main_footer_block">
            <!-- justify-content-center text-center justify-content-md-left text-md-left-->
            <div class="col-12 col-md-4 text-center text-md-left promo_block pl-0 pl-md-3">
              <div class="copyright1">
                &copy; Mike Mozolin, 1997-{{ currYear }}<br/>
                 <a href="mailto:mozolin@gmail.com">mozolin@gmail.com</a>
               </div>
            </div>
            <div class="d-none d-md-block col-12 col-md-4 promo_block justify-content-right pr-0 pr-md-3">
              <!--
              <?
              $stls = "float-none float-md-right promo";
              ?>
              <div class="<?=$stls?>" title="<?=$MikeOnGoogle?>">
                <a href="https://plus.google.com/108218227065119062014" target="_blank"><img src="/skins/<?=SKIN?>/images/social/gp_32x32.png"/></a>
              </div>
              <?/*?>
              <div class="<?=$stls?>" title="<?=$MikeOnFacebook?>">
                <a href="https://facebook.com/mike.mozolin" target="_blank"><img src="/skins/<?=SKIN?>/images/social/fb_32x32.png"/></a>
              </div>
              <?*/?>
              <div class="<?=$stls?>" title="<?=$MikeOnVK?>">
                <a href="https://vk.com/mikemozolin" target="_blank"><img src="/skins/<?=SKIN?>/images/social/vk_32x32.png"/></a>
              </div>
              <div class="<?=$stls?>" title="<?=$MikeOnAirbnb?>">
                <a href="https://www.airbnb.ru/c/mmozolin?locale=<?=LANGUAGE?>" target="_blank"><img src="/skins/<?=SKIN?>/images/social/airbnb_32x32.png"/></a>
              </div>
              <div class="<?=$stls?>" title="<?=$MikeOnFozzy?>">
                <a href="https://accounts.fozzy.com/aff.php?aff=5993" target="_blank"><img src="/skins/<?=SKIN?>/images/social/fozzy_logo_32x32.png"/></a>
              </div>
              -->
            </div>

            <div class="d-block d-md-none col-12 col-md-4 promo_block">
              <div class="row no-gutters justify-content-center">
                <!--
                <?
                $stls = "col-auto promo";
                ?>
                <div class="<?=$stls?>" title="<?=$MikeOnGoogle?>">
                  <a href="https://plus.google.com/108218227065119062014" target="_blank"><img src="/skins/<?=SKIN?>/images/social/gp_32x32.png"/></a>
                </div>
                <?/*?>
                <div class="<?=$stls?>" title="<?=$MikeOnFacebook?>">
                  <a href="https://facebook.com/mike.mozolin" target="_blank"><img src="/skins/<?=SKIN?>/images/social/fb_32x32.png"/></a>
                </div>
                <?*/?>
                <div class="<?=$stls?>" title="<?=$MikeOnVK?>">
                  <a href="https://vk.com/mikemozolin" target="_blank"><img src="/skins/<?=SKIN?>/images/social/vk_32x32.png"/></a>
                </div>
                <div class="<?=$stls?>" title="<?=$MikeOnAirbnb?>">
                  <a href="https://www.airbnb.ru/c/mmozolin?locale=<?=LANGUAGE?>" target="_blank"><img src="/skins/<?=SKIN?>/images/social/airbnb_32x32.png"/></a>
                </div>
                <div class="<?=$stls?>" title="<?=$MikeOnFozzy?>">
                  <a href="https://accounts.fozzy.com/aff.php?aff=5993" target="_blank"><img src="/skins/<?=SKIN?>/images/social/fozzy_logo_32x32.png"/></a>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import { Funcs } from '@/plugins/Funcs.js'

export default {
  name: 'PageFooter',
  data () {
    return {
      
    }
  },
  computed: {
    currYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    goToTop() {
      Funcs.scrollToTop()
    }
  },
}
</script>
